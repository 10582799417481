.stroke-on-hover-parent:hover .stroke-on-hover {
	stroke: rgb(75, 75, 75);
	stroke-width: 0.2px;
	stroke-dashoffset: 5;
	stroke-dasharray: 5;
}
.blink {
	animation: blink 200ms ease infinite forwards;
}

.loading-dots {
	display: flex;
	justify-content: center;
	align-items: center;
}

.loading-dots .dot {
	border-radius: 50%;
	animation: wave 1.2s linear infinite;
}

.loading-dots .dot:nth-child(1) {
	animation-delay: 0s;
}

.loading-dots .dot:nth-child(2) {
	animation-delay: 0.2s;
}

.loading-dots .dot:nth-child(3) {
	animation-delay: 0.4s;
}

.animation-shake {
	animation: shake 400ms linear infinite;
}

.animation-shake-twice {
	animation: shake-twice 400ms linear 2;
}

@keyframes wave {
	0%,
	60%,
	100% {
		transform: initial;
	}
	30% {
		transform: translateY(-5px);
	}
}

@keyframes blink {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 0.5;
	}
}

@keyframes shake {
	0%,
	100% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(2deg);
	}
	50% {
		transform: rotate(0deg);
	}
	70% {
		transform: rotate(-2deg);
	}
}

@keyframes shake-twice {
	0%,
	100% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(0.5deg);
	}
	50% {
		transform: rotate(0deg);
	}
	70% {
		transform: rotate(-0.5deg);
	}
}
