video {
	background: #000;
}

.ai-tutor-video-element {
	width: 90%;
	max-width: 500px;
	height: auto;
	border-radius: 10px;
	flex: 1 0 auto;
	min-height: 0;
}

.video-title {
	color: #fff;
	padding: 0 10px;
	position: relative;
	z-index: 12;
	pointer-events: none;
}

.video-container {
	position: relative;
	display: flex;
	flex-grow: 1;
	justify-content: center;
	margin-inline: auto;
	user-select: none;
}

.video-container .video-player {
	width: 100%;
	height: 100%;
}

.video-container.fullscreen {
	height: 100vh;
	width: 100%;
}

.video-container.fullscreen .video-player {
	height: 100%;
	width: 100%;
}

/* .video-controls:not(.paused),
.video-controls:not(.paused) .video-title {
	transition: opacity 1s ease !important;
	opacity: 0;
} */

.video-controls {
	cursor: pointer;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	pointer-events: none;
	position: relative;
}

.title-and-timeline .overlay {
	position: absolute;
	pointer-events: none;
	left: 0;
	right: 0;
	z-index: 0;
	bottom: 0;
	height: 220%;
	background: linear-gradient(to top, rgba(0, 0, 0, 0.95), transparent);
}

.video-controls-container {
	position: absolute;
	inset: 0;
	display: flex;
	flex-direction: column;
}

.pause-play-box {
	flex-grow: 1;
}

.title-and-timeline {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	opacity: 0;
	transition: opacity 1s ease !important;
}

.paused,
.hovered,
.paused .title-and-timeline,
.hovered .title-and-timeline {
	opacity: 1 !important;
	color: white !important;
}

.control-btn {
	padding: 0;
	color: white;
	background: none;
	border: none;
	cursor: pointer;
	/* transition: transform 0.1s ease; */
}

.control-btn:disabled {
	pointer-events: none;
	color: #6d6d6d;
}

.play-pause-btn {
	padding: 0;
	color: white;
	background: #fff;
	background: none;
	border: none;
	position: relative;
	border-radius: 50%;
	width: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1/1;
	overflow: hidden;
}

.play-pause-btn * {
	z-index: 10;
	position: relative;
}

.play-pause-btn .backdrop {
	position: absolute;
	inset: 0;
	z-index: 0;
	background: rgba(125, 124, 124, 0.7);
	backdrop-filter: blur(5px);
	border-radius: 50%;
	box-shadow: 10px 5px 20px 5px rgba(0, 0, 0, 0.95);
	width: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1/1;
}

.control-btn:hover {
	transform: scale(1.05);
}

.volume-container {
	position: relative;
	display: flex;
	align-items: center;
	gap: 5px;
}

.slider-background {
	position: absolute;
	width: 30px;
	height: 0;
	background: grey;
	border-radius: 5px;
	bottom: calc(100% + 0px);
	z-index: 100;
	left: 50%;
	transform: translateX(-50%);
}

.volume-slider[type='range'][orient='vertical'] {
	position: absolute;
	z-index: 110;
	bottom: calc(100% + 10px);
	left: 40%;
	transform: translateX(-50%);
	writing-mode: vertical-lr;
	direction: rtl;
	height: 0;
	width: 1px;
	opacity: 0;
	/* transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out; */
	appearance: none;
}

.volume-slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 15px;
	height: 15px;
	background: white;
	border-radius: 50%;
}
.volume-container:hover .volume-slider {
	height: fit-content;
	opacity: 1;
}

.volume-container:hover .slider-background {
	height: 150px;
}

.timeline-container {
	height: 7px;
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	margin-inline: 8px;
	pointer-events: all;
}

.timeline-container.scrubbing .timeline,
.timeline-container:hover .timeline {
	height: 100%;
}

.timeline {
	height: 2px;
	background: rgb(20, 20, 20);
	width: 100%;
	position: relative;
}

.timeline::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: calc(100% - var(--preview-position) * 100%);
	background: rgb(92, 92, 92);
	display: none;
}

.timeline::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: calc(100% - var(--progress-position) * 100%);
	background: #fff;
}

.buffer-loaded {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: calc(100% - var(--buffer-position) * 100%);
	background: rgba(245, 245, 245, 0.7);
}

.timeline-container.scrubbing .timeline::before,
.timeline-container:hover .timeline::before {
	display: block;
}

.thumb-indicator {
	--scale: 0;
	position: absolute;
	transform: translateX(-50%) scale(var(--scale));
	height: 200%;
	top: -50%;
	left: calc(var(--progress-position) * 100%);
	background: #fff;
	border-radius: 50%;
	/* transition: transform 150ms ease-in-out; */
	aspect-ratio: 1/1;
	z-index: 10;
}

.timeline-container.scrubbing .thumb-indicator,
.timeline-container:hover .thumb-indicator {
	--scale: 1;
}

@media (max-width: 900px) {
	.video-player {
		width: 100%;
	}
}

@keyframes fade-overlay {
	to {
		opacity: 0;
	}
}
