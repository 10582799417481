@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
	scroll-behavior: smooth;
}

.react-pdf__Document {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.react-pdf__Page {
	flex-shrink: 0;
	flex-grow: 1;
}

.react-doc-viewer-custom {
	height: 100%;
	flex-grow: 1;
	position: relative;
	background: none !important;
}

#image-renderer {
	background: none !important;
}

.react-doc-viewer-custom.docx::after {
	background: white;
	height: 21px;
}

.react-doc-viewer-custom.pptx::after {
	background: #444;
	height: 22px;
}

.react-doc-viewer-custom::after {
	content: '';
	width: 100px;
	bottom: 0;
	right: 40px;
	height: 23px;
	position: absolute;
}

.react-doc-viewer-custom #header-bar {
	display: none;
}

.react-doc-viewer-custom #proxy-renderer {
	overflow: hidden;
}

.katex-html {
	display: none;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 10px !important ;
	height: 10px !important ;
}
.custom-scrollbar::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
	background: #999;
}
.dark .custom-scrollbar::-webkit-scrollbar-track {
	background: #646464;
}
.dark .custom-scrollbar::-webkit-scrollbar-thumb {
	background: #666;
}

.sidebar-scrollbar::-webkit-scrollbar {
	width: 3px !important ;
	height: 3px !important ;
	overflow: hidden !important;
}
.sidebar-scrollbar::-webkit-scrollbar-track {
	background: transparent;
}
.sidebar-scrollbar::-webkit-scrollbar-thumb {
	background: whitesmoke;
	opacity: 0;
	border-radius: 3px;
}

.sidebar-scrollbar:hover::-webkit-scrollbar {
	overflow: auto !important;
}

.slim-custom-scrollbar {
	overflow: hidden;
}

.slim-custom-scrollbar:hover {
	overflow: auto;
}

.slim-custom-scrollbar::-webkit-scrollbar {
	width: 4px !important ;
	height: 4px !important ;
	border-radius: 4px;
}
.slim-custom-scrollbar::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 4px;
}
.slim-custom-scrollbar::-webkit-scrollbar-thumb {
	background: #999;
	border-radius: 4px;
}
.dark .slim-custom-scrollbar::-webkit-scrollbar-track {
	background: #2e2e2e;
}
.dark .slim-custom-scrollbar::-webkit-scrollbar-thumb {
	background: #dbdbdb;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
	width: 0 !important;
	height: 0 !important;
}

:root {
	--toastify-toast-min-height: 45px !important;
	--toastify-toast-max-height: 55px;
	--toastify-color-light: #333333 !important;
	--toastify-text-color-light: #f5f5f5 !important;
	--toastify-font-family: 'Roboto' !important;
	--primary: #273679;
}
.Toastify__close-button--light {
	opacity: 1 !important;
	color: #fff !important;
}

body,
html {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
}

#root {
	height: 100%;
	max-height: 100%;
	background: #f7f7fa;
	overflow: hidden;
}

.m-0 {
	margin: 0 !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.code-button {
	background-color: #f3f3f3;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 4px 8px;
	font-family: monospace;
	cursor: pointer;
	display: inline-block;
}

.code-button.small {
	padding: 2px 4px;
}

.code-button.large {
	padding: 6px 10px;
	font-size: 18px;
}

.code-button.dark {
	background-color: #464646;
}

a {
	text-decoration: none !important;
}

.default-link {
	color: inherit;
	text-decoration: none;
}

.questionbank-wysiwyg-container p {
	margin: 0 !important;
}

.ai-response-language {
	padding: 10px;
	border-radius: 10px 10px 0 0;
	background-color: #000;
	color: #fff;
	font-size: 12px;
}

.ai-response {
	font-size: 0.9rem;
}

.ai-response pre {
	margin-top: 0;
	/* border: 1px solid gray; */
	padding: 10px;
	overflow: auto;
	border-radius: 0 0 10px 10px;
}

.ai-response.dark pre {
	background: #a2a2a2aa;
}

.ai-response.light pre {
	background: lightgrey;
}

.ai-response pre.language-python {
	position: relative;
	margin-top: 40px;
	color: black;
}

.ai-response pre.language-python::before {
	position: absolute;
	/* bottom: 100%; */
	top: -10px;
	left: -0px;
	border-radius: 10px 10px 0 0;
	right: 0;
	padding: 5px 10px;
	content: 'main.py';
	background: #000;
	color: white;
	font-size: 12px;
}

.editable-name-element {
	/* outline: none; */
	border: 1px dotted #0008;
	padding: 0 5px;
	border-radius: 5px;
	max-width: 35vw;
	white-space: nowrap;
	overflow-x: auto;
}

.editable-name-element:hover {
	border: 1px solid #000;
}

.editable-name-element:focus {
	border: 2px solid var(--primary);
}

.announcement-name-editable-element {
	border-bottom: 1px solid #0008;
	padding: 0 5px;
	display: flex;
	gap: 10px;
	align-items: flex-start;
	justify-content: space-between;
	font-style: italic;
	width: fit-content;
	overflow-x: auto;
	outline: none;
}

.dark .announcement-name-editable-element {
	border-bottom: 1px solid #afafaf;
}

.announcement-name-editable-element:focus {
	border: 2px solid var(--primary);
}

#embedd-bbb {
	height: 90vh;
	width: 100%;
	border: none;
}
#embedd-bbb button #tippy-3 {
	display: none !important;
}

.pushed-appbar {
	width: calc(100% - 300px) !important;
	margin-left: 300px;
}
.fullwidth-appbar {
	width: 100% !important;
	margin-left: 300px;
}

.app-bar {
	width: calc(100% - 300px) !important;
	margin-left: 300px;
}
.opened-app-bar {
	width: 100% !important;
	margin-left: 300px;
}
.full-width {
	width: 100% !important;
	margin-left: 300px;
}

.questionNbr {
	display: 'flex';
	padding: '10px';
	width: '30px';
	height: '30px';
	min-width: '30px';
	min-height: '30px';
	align-items: 'center';
	justify-content: 'center';
	border-radius: '50%';
	background: '#aaa';
	font-size: '12px';
	font-weight: 'bold';
}

.answered {
	position: 'relative';
	background: 'green';
	color: 'white';
}

.default-thumb-preview {
	cursor: pointer;
	border: 2px solid grey;
	border-radius: 5px;
}

.default-thumb-preview.selected-preview-img {
	border: 5px solid #f18f08;
}

.notAnswered {
	position: 'relative';
	background: '#ed3447';
	color: 'white';
}

.breadcrumb-link {
	text-decoration: underline !important;
	color: inherit !important;
}

.thumbnail-input,
.custom-file-input {
	opacity: 0;
	position: absolute;
	top: 0;
	cursor: pointer;
	right: 0;
	left: 0;
	bottom: 0;
}

.full-screen-element {
	background: white;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
	width: 100%;
}

.full-screen-element.dark {
	background: #000;
}

.template-builder-full-screen {
	background: #f4f8f9;
	padding: 20px;
	height: 100%;
}

.table-row {
	position: relative;
}

.table-row::after {
	content: '';
	background: #d3d3d3;
	position: absolute;
	height: 0.05em;
	width: calc(100% - 40px);
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.custom-table-row {
	position: relative;
}

.custom-table-row::after {
	content: '';
	background: #d3d3d3;
	position: absolute;
	height: 0.05em;
	width: calc(100% - 40px);
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.invisible-element {
	width: 0;
	height: 0;
	opacity: 0;
}

.pop-alert {
	transform: translate(0, 0) !important;
	opacity: 1 !important;
	transition: 200ms !important;
}

.draggable-ele {
	transition: 1s;
}

.show-on-hover-parent .show-on-hover {
	display: none;
}

.show-on-hover-parent:hover .show-on-hover {
	display: block;
}

#calendar-view {
	margin-left: 3px;
}
.react-calendar__month-view__weekdays__weekday abbr {
	text-decoration: none !important;
}

.react-calendar__month-view__days__day {
	width: 38px !important;
	height: 38px !important;
	border-radius: 50% !important;
	padding: 5px !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.react-calendar__month-view__days__day abbr {
	padding: 10px;
	display: block;
	width: 38px;
	height: 38px;
	border-radius: 50%;
}

.react-calendar__month-view__days__day:hover {
	background: none !important;
}

.react-calendar__navigation__label {
	background-color: #eee;
	font-weight: bold;
}

.react-calendar__tile--now {
	background: #fff !important;
	color: black;
	border-radius: 50%;
}

.react-calendar__month-view__days__day:hover abbr {
	background: #eee !important;
}

.react-calendar__tile--now abbr {
	background: #273679;
	color: white;
}

.react-calendar__tile--now:hover abbr {
	background: #273679 !important;
	color: white !important;
}

.react-calendar__tile--active {
	background: none !important;
	color: black !important;
}

.faq-step-box {
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 5px;
	padding: 5px;
}

.faq-step-box em,
.faq-em {
	font-size: 18px;
	font-weight: 600;
}

.faq-step-box a {
	font-style: italic;
	font-weight: 600;
	color: #555;
	text-decoration: underline !important;
}

.faq-answer-header {
	margin: 0;
	color: #273679;
}

.faq-step-box a:hover {
	color: #aaa;
}

.faq-step-box p {
	font-weight: 400;
}

.faq-step {
	z-index: 2;
	border-radius: 50%;
	height: 20px;
	aspect-ratio: 1;
	font-weight: bolder;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: #49982e 1px solid;
	background: white;
	color: #000;
}

.ql-editing {
	z-index: 13000;
	left: 100px !important;
	top: 10px !important;
	background: white;
}

/* Progress bar - circular */
.svg-pi-indicator {
	transition: all 200ms linear;
}

.svg-pi-wrapper {
	position: relative;
}

.svg-pi {
	transform: rotate(-90deg); /* Fix the orientation */
}

/* Animated spinner version */
.svg-pi-indicator--spinner {
	animation: spinner 0.75s linear infinite;
	transform-origin: center;
}

.svg-pi-label {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.svg-pi-label__loading {
	opacity: 0.5;
	font-size: 0.75em;
}

.svg-pi-label__progress {
	font-size: 1.1em;
	font-weight: bold;
}

.svg-pi-label__loading,
.svg-pi-label__progress {
	display: block;
}

/* Spinner animation */
@keyframes spinner {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.mq-editable-field {
	border-radius: 5px !important;
}

.mq-editable-field.mq-focused {
	box-shadow: none !important;
	border-color: inherit !important;
	border-width: 2px;
}

.mq-math-mode,
.mq-math-mode .mq-editable-field {
	padding: 10px;
	width: '100%';
	flex-grow: 1;
	font-size: 16px !important;
	font-family: 'Roboto', sans-serif !important;
	font-style: normal !important;
}

.mq-math-mode var,
.mq-math-mode i,
.mq-math-mode i.mq-font {
	font-style: normal !important;
	font-family: 'Roboto' sans-serif !important;
	font-weight: 400 !important;
	font-size: 1em !important;
}

.mq-editable-field {
	display: -moz-inline-box;
	display: inline-block;
}
.mq-editable-field .mq-cursor {
	border-left: 1px solid black;
	margin-left: -1px;
	position: relative;
	z-index: 1;
	padding: 0;
	display: -moz-inline-box;
	display: inline-block;
}
.mq-editable-field .mq-cursor.mq-blink {
	visibility: hidden;
}
.mq-editable-field,
.mq-math-mode .mq-editable-field {
	border: 1px solid gray;
}
.mq-editable-field.mq-focused,
.mq-math-mode .mq-editable-field.mq-focused {
	-webkit-box-shadow: #8bd 0 0 1px 2px, inset #6ae 0 0 2px 0;
	-moz-box-shadow: #8bd 0 0 1px 2px, inset #6ae 0 0 2px 0;
	box-shadow: #8bd 0 0 1px 2px, inset #6ae 0 0 2px 0;
	border-color: #709ac0;
	border-radius: 1px;
}
.mq-math-mode .mq-editable-field {
	margin: 1px;
}
.mq-editable-field .mq-latex-command-input {
	color: inherit;
	font-family: 'Courier New', monospace;
	border: 1px solid gray;
	padding-right: 1px;
	margin-right: 1px;
	margin-left: 2px;
}
.mq-editable-field .mq-latex-command-input.mq-empty {
	background: transparent;
}
.mq-editable-field .mq-latex-command-input.mq-hasCursor {
	border-color: ActiveBorder;
}
.mq-editable-field.mq-empty:after,
.mq-editable-field.mq-text-mode:after,
.mq-math-mode .mq-empty:after {
	visibility: hidden;
	content: 'c';
}
.mq-editable-field .mq-cursor:only-child:after,
.mq-editable-field .mq-textarea + .mq-cursor:last-child:after {
	visibility: hidden;
	content: 'c';
}
.mq-editable-field .mq-text-mode .mq-cursor:only-child:after {
	content: '';
}
.mq-editable-field.mq-text-mode {
	overflow-x: auto;
	overflow-y: hidden;
}
.mq-root-block,
.mq-math-mode .mq-root-block {
	display: -moz-inline-box;
	display: inline-block;
	width: 100%;
	padding: 2px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: middle;
}
.mq-math-mode {
	font-variant: normal;
	font-weight: normal;
	font-style: normal;
	font-size: 115%;
	line-height: 1;
	display: -moz-inline-box;
	display: inline-block;
}
.mq-math-mode .mq-non-leaf,
.mq-math-mode .mq-scaled {
	display: -moz-inline-box;
	display: inline-block;
}
.mq-math-mode var,
.mq-math-mode .mq-text-mode,
.mq-math-mode .mq-nonSymbola {
	font-family: 'Roboto', sans-serif !important;
	font-size: 14px;
	line-height: 0.9;
}
.mq-math-mode * {
	font-size: inherit;
	line-height: inherit;
	margin: 0;
	padding: 0;
	border-color: black;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	box-sizing: border-box;
}
.mq-math-mode .mq-empty {
	background: #ccc;
}
.mq-math-mode .mq-empty.mq-root-block {
	background: transparent;
}
.mq-math-mode.mq-empty {
	background: transparent;
}
.mq-math-mode .mq-text-mode {
	font-size: 87%;
}
.mq-math-mode .mq-font {
	font-family: 'Roboto', sans-serif !important;
}
.mq-math-mode .mq-font * {
	font-family: inherit;
	font-style: inherit;
}
.mq-math-mode b,
.mq-math-mode b.mq-font {
	font-weight: 400;
}
.mq-math-mode var,
.mq-math-mode i,
.mq-math-mode i.mq-font {
	font-style: normal;
}
.mq-math-mode var.mq-f {
	margin-right: 0.2em;
	margin-left: 0.1em;
}
.mq-math-mode .mq-roman var.mq-f {
	margin: 0;
}
.mq-math-mode big {
	font-size: 125%;
}
.mq-math-mode .mq-roman {
	font-style: normal;
}
.mq-math-mode .mq-sans-serif {
	font-family: sans-serif, Symbola, serif;
}
.mq-math-mode .mq-monospace {
	font-family: monospace, Symbola, serif;
}
.mq-math-mode .mq-overline {
	border-top: 1px solid black;
	margin-top: 1px;
}
.mq-math-mode .mq-underline {
	border-bottom: 1px solid black;
	margin-bottom: 1px;
}
.mq-math-mode .mq-binary-operator {
	padding: 0 0.2em;
	display: -moz-inline-box;
	display: inline-block;
}
.mq-math-mode .mq-supsub {
	font-size: 90%;
	vertical-align: -0.5em;
}
.mq-math-mode .mq-supsub.mq-limit {
	font-size: 80%;
	vertical-align: -0.4em;
}
.mq-math-mode .mq-supsub.mq-sup-only {
	vertical-align: 0.5em;
}
.mq-math-mode .mq-supsub.mq-sup-only .mq-sup {
	display: inline-block;
	vertical-align: text-bottom;
}
.mq-math-mode .mq-supsub .mq-sup {
	display: block;
}
.mq-math-mode .mq-supsub .mq-sub {
	display: block;
	float: left;
}
.mq-math-mode .mq-supsub.mq-limit .mq-sub {
	margin-left: -0.25em;
}
.mq-math-mode .mq-supsub .mq-binary-operator {
	padding: 0 0.1em;
}
.mq-math-mode .mq-supsub .mq-fraction {
	font-size: 70%;
}
.mq-math-mode sup.mq-nthroot {
	font-size: 80%;
	vertical-align: 0.8em;
	margin-right: -0.6em;
	margin-left: 0.2em;
	min-width: 0.5em;
}
.mq-math-mode .mq-paren {
	padding: 0 0.1em;
	vertical-align: top;
	-webkit-transform-origin: center 0.06em;
	-moz-transform-origin: center 0.06em;
	-ms-transform-origin: center 0.06em;
	-o-transform-origin: center 0.06em;
	transform-origin: center 0.06em;
}
.mq-math-mode .mq-paren.mq-ghost {
	color: silver;
}
.mq-math-mode .mq-paren + span {
	margin-top: 0.1em;
	margin-bottom: 0.1em;
}
.mq-math-mode .mq-array {
	vertical-align: middle;
	text-align: center;
}
.mq-math-mode .mq-array > span {
	display: block;
}
.mq-math-mode .mq-operator-name {
	font-family: Symbola, 'Times New Roman', serif;
	line-height: 0.9;
	font-style: normal;
}
.mq-math-mode var.mq-operator-name.mq-first {
	padding-left: 0.2em;
}
.mq-math-mode var.mq-operator-name.mq-last {
	padding-right: 0.2em;
}
.mq-math-mode .mq-fraction {
	font-size: 90%;
	text-align: center;
	vertical-align: -0.4em;
	padding: 0 0.2em;
}
.mq-math-mode .mq-fraction,
.mq-math-mode .mq-large-operator,
.mq-math-mode x:-moz-any-link {
	display: -moz-groupbox;
}
.mq-math-mode .mq-fraction,
.mq-math-mode .mq-large-operator,
.mq-math-mode x:-moz-any-link,
.mq-math-mode x:default {
	display: inline-block;
}
.mq-math-mode .mq-numerator,
.mq-math-mode .mq-denominator {
	display: block;
}
.mq-math-mode .mq-numerator {
	padding: 0 0.1em;
}
.mq-math-mode .mq-denominator {
	border-top: 1px solid;
	float: right;
	width: 100%;
	padding: 0.1em;
}
.mq-math-mode .mq-sqrt-prefix {
	padding-top: 0;
	position: relative;
	top: 0.1em;
	vertical-align: top;
	-webkit-transform-origin: top;
	-moz-transform-origin: top;
	-ms-transform-origin: top;
	-o-transform-origin: top;
	transform-origin: top;
}
.mq-math-mode .mq-sqrt-stem {
	border-top: 1px solid;
	margin-top: 1px;
	padding-left: 0.15em;
	padding-right: 0.2em;
	margin-right: 0.1em;
	padding-top: 1px;
}
.mq-math-mode .mq-vector-prefix {
	display: block;
	text-align: center;
	line-height: 0.25em;
	margin-bottom: -0.1em;
	font-size: 0.75em;
}
.mq-math-mode .mq-vector-stem {
	display: block;
}
.mq-math-mode .mq-large-operator {
	text-align: center;
}
.mq-math-mode .mq-large-operator .mq-from,
.mq-math-mode .mq-large-operator big,
.mq-math-mode .mq-large-operator .mq-to {
	display: block;
}
.mq-math-mode .mq-large-operator .mq-from,
.mq-math-mode .mq-large-operator .mq-to {
	font-size: 80%;
}
.mq-math-mode .mq-large-operator .mq-from {
	float: right;
	/* take out of normal flow to manipulate baseline */
	width: 100%;
}
.mq-math-mode,
.mq-math-mode .mq-editable-field {
	cursor: text;
	font-family: Symbola, 'Times New Roman', serif;
}
.mq-math-mode .mq-overarrow {
	border-top: 1px solid black;
	margin-top: 1px;
	padding-top: 0.2em;
}
.mq-math-mode .mq-overarrow:before {
	display: block;
	position: relative;
	top: -0.34em;
	font-size: 0.5em;
	line-height: 0em;
	content: '\27A4';
	text-align: right;
}
.mq-math-mode .mq-overarrow.mq-arrow-left:before {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	filter: FlipH;
	-ms-filter: 'FlipH';
}
.mq-math-mode .mq-selection,
.mq-editable-field .mq-selection,
.mq-math-mode .mq-selection .mq-non-leaf,
.mq-editable-field .mq-selection .mq-non-leaf,
.mq-math-mode .mq-selection .mq-scaled,
.mq-editable-field .mq-selection .mq-scaled {
	background: #b4d5fe !important;
	background: Highlight !important;
	color: HighlightText;
	border-color: HighlightText;
}
.mq-math-mode .mq-selection .mq-matrixed,
.mq-editable-field .mq-selection .mq-matrixed {
	background: #39f !important;
}
.mq-math-mode .mq-selection .mq-matrixed-container,
.mq-editable-field .mq-selection .mq-matrixed-container {
	filter: progid:DXImageTransform.Microsoft.Chroma(color='#3399FF') !important;
}
.mq-math-mode .mq-selection.mq-blur,
.mq-editable-field .mq-selection.mq-blur,
.mq-math-mode .mq-selection.mq-blur .mq-non-leaf,
.mq-editable-field .mq-selection.mq-blur .mq-non-leaf,
.mq-math-mode .mq-selection.mq-blur .mq-scaled,
.mq-editable-field .mq-selection.mq-blur .mq-scaled,
.mq-math-mode .mq-selection.mq-blur .mq-matrixed,
.mq-editable-field .mq-selection.mq-blur .mq-matrixed {
	background: #d4d4d4 !important;
	color: black;
	border-color: black;
}
.mq-math-mode .mq-selection.mq-blur .mq-matrixed-container,
.mq-editable-field .mq-selection.mq-blur .mq-matrixed-container {
	filter: progid:DXImageTransform.Microsoft.Chroma(color='#D4D4D4') !important;
}
.mq-editable-field .mq-textarea,
.mq-math-mode .mq-textarea {
	position: relative;
	-webkit-user-select: text;
	-moz-user-select: text;
	user-select: text;
}
.mq-editable-field .mq-textarea *,
.mq-math-mode .mq-textarea *,
.mq-editable-field .mq-selectable,
.mq-math-mode .mq-selectable {
	-webkit-user-select: text;
	-moz-user-select: text;
	user-select: text;
	position: absolute;
	clip: rect(1em 1em 1em 1em);
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	resize: none;
	width: 1px;
	height: 1px;
}
.mq-math-mode .mq-matrixed {
	background: white;
	display: -moz-inline-box;
	display: inline-block;
}
.mq-math-mode .mq-matrixed-container {
	filter: progid:DXImageTransform.Microsoft.Chroma(color='white');
	margin-top: -0.1em;
}

.static-math-mode {
	padding: 0 !important;
}

#static-latex {
	font-size: 18px !important;
	padding: 0 !important;
	font-weight: 600 !important;
	border: none !important;
	outline: none !important;
	pointer-events: none !important;
	font-family: 'Roboto' sans-serif !important;
	font-style: normal !important;
	width: 100%;
	word-break: break-word;
}

#static-latex .mq-root-block > var,
#static-latex .mq-root-block > span {
	font-size: 16px !important;
	padding: 0 !important;
	font-weight: 600 !important;
	border: none !important;
	outline: none !important;
	pointer-events: none !important;
	font-family: 'Roboto' sans-serif !important;
	font-style: normal !important;
	word-break: break-word;
	white-space: wrap;
}

#answer-section-latex {
	border: none !important;
	outline: none !important;
	pointer-events: none !important;
	font-family: 'Roboto' sans-serif !important;
	font-style: normal !important;
}

#answer-section-latex .mq-root-block > var,
#answer-section-latex .mq-root-block > span {
	font-family: 'Roboto' sans-serif !important;
	font-size: 18px !important;
}

.bg-pattern {
	background-image: url('../../public/imgs/bg-pattern1.png');
	background-repeat: repeat;
}

.shadow-on-left-border {
	position: relative;
	background: white;
	box-shadow: -5px 0px 5px 0px #dbdbdbab;
}

.shadow-on-left-border.dark {
	box-shadow: -5px 0px 5px 0px #2d2d2dd7;
}

/* .shadow-on-left-border::before {
	display: block;
	content: '';
	height: calc(100% + 1px);
	position: absolute;
	right: 100%;
	top: 0;
	z-index: 0;
	width: 10px;
	background: linear-gradient(to left, rgba(0, 0, 0, 0.094), transparent);
} */

.ripple-static,
.ripple-animate {
	background: linear-gradient(180deg, #fcab28 0%, #ff2b59 100%);
	padding: '20px';
	position: 'relative';
}

.ripple-animate::after,
.ripple-animate::before,
.ripple-static::after,
.ripple-static::before {
	content: '';
	border: 1px solid #e25a26;
	border-radius: 50%;
	width: 140%;
	aspect-ratio: 1;
	position: absolute;
	z-index: 0;
}

.ripple-static::after {
	border: 1px solid #e25a2680;
}

.ripple-static::before {
	width: 180%;
	border: 1px solid #e25a2640;
}
.ripple-animate::before {
	width: 180%;
}

.ripple-animate::after,
.ripple-animate::before {
	animation-name: ripple-effect;
	animation-duration: 2s;
	animation-delay: 0s;
	animation-iteration-count: infinite;
}

@media (max-width: 900px) {
	.answered {
		background: 'none';
		color: 'black';
		border: '2px solid green';
	}
}

@media (max-width: 900px) {
	.notAnswered {
		background: 'none';
		color: 'black';
		border: '2px solid #ed3447';
	}
}

@keyframes ripple-effect {
	from {
		opacity: 1;
		transform: scale(0);
	}
	to {
		opacity: 0;
		transform: scale(1);
	}
}

@media print {
	@page {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	body {
		margin: 0;
		padding: 0;
	}
}
