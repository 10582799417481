.graph-svg-container {
	width: 100%;
	flex-grow: 1;
}

.pie-chart-svg-container {
	width: 100%;
	flex-grow: 1;
}

.graph-axis .domain,
.graph-axis .tick line {
	stroke: #d3d3d3;
}

.graph-axis .tick text {
	fill: currentColor;
}

.graph-data-point-vertical-line {
	pointer-events: none;
	/* opacity: 0; */
	display: none;
	stroke-width: 2;
	stroke-dasharray: 4;
	stroke-dashoffset: 4;
}

.graph-data-point-vertical-line-ref {
	stroke: #eee1;
	cursor: pointer;
}

.graph-data-point-vertical-line-ref:hover {
	stroke: #eee4;
}

.graph-data-point {
	position: relative;
	z-index: 5;
}

.graph-data-details {
	display: none;
	position: relative;
	z-index: 1000;
	/* transform: translateX(-50px); */
}

.graph-data-point-details-text {
	font-size: 10px;
	font-weight: 400;
}

.text-2x {
	font-size: 14px;
	font-weight: 600;
}

.graph-axis-label {
	font-size: 12px;
	color: #d3d3d3;
}

.graph-data-group:hover .graph-data-point-vertical-line,
.graph-data-group:hover .graph-data-details {
	/* opacity: 1; */
	display: block;
}

.pie-chart-arc {
	cursor: pointer;
	transition: 1s ease;
	filter: url('#none-filter');
}

.pie-chart-arc:hover {
	filter: url('#shadow');
	transition: 1s ease;
}

.pie-chart-arc-label {
	pointer-events: none;
}
