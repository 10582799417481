.comment-reference-link {
	color: inherit !important;
	text-decoration: underline !important;
}

.sticky-header-link {
	color: inherit !important;
}
.sticky-header-link:hover {
	text-decoration: underline !important;
}

.wysiwyg-text-container pre,
.quiz-dialog-wysiwyg-container pre {
	background: #eee6;
	padding: 10px;
	border-radius: 5px;
	color: inherit;
}

.wysiwyg-text-container blockquote,
.quiz-dialog-wysiwyg blockquote {
	border: inherit;
	border-left: 5px solid #aaa;
	background: #eee6;
	border-radius: 0 5px 5px 0;
	width: fit-content;
	margin: 5px 0;
	padding: 20px;
}

.wysiwyg-text-container p {
	margin: 5px 0;
	font-size: 15px;
	text-align: justify;
}

.quiz-dialog-wysiwyg p {
	margin: 0;
	font-size: 12px;
	text-align: justify;
}

.dark .ql-editor.ql-blank::before {
	color: #7f7f7f !important;
}

.light .ql-editor.ql-blank::before {
	color: #b3b3b3 !important;
}

.quill > .ql-container > .ql-formats button {
	color: #fff;
}

.ql-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.ql-container .ql-editor {
	flex-grow: 1;
}

.ql-toolbar .ql-stroke {
	fill: none;
	stroke: #fff;
}

.ql-toolbar .ql-fill {
	fill: #fff;
	stroke: none;
}

.ql-toolbar .ql-picker {
	color: #fff;
}

.questionbank-wysiwyg-container * {
	color: inherit;
	background: none !important;
	background-color: none !important;
}

.wysiwyg-text-container .ql-size-large,
.quiz-dialog-wysiwyg .ql-size-large {
	font-size: 0.7em;
}

.wysiwyg-text-container .ql-size-huge,
.quiz-dialog-wysiwyg .ql-size-huge {
	font-size: 2.5em;
}

.wysiwyg-text-container li[data-list='bullet'],
.quiz-dialog-wysiwyg li[data-list='bullet'] {
	list-style: disc;
}

.wysiwyg-text-container .ql-size-large,
.quiz-dialog-wysiwyg .ql-size-large {
	font-size: 1.5em;
}

.wysiwyg-text-container .ql-font-monospace,
.quiz-dialog-wysiwyg .ql-font-monospace {
	font-family: monospace;
}

.wysiwyg-text-container .ql-font-serif,
.quiz-dialog-wysiwyg .ql-font-serif {
	font-family: serif;
}

.wysiwyg-text-container a,
.quiz-dialog-wysiwyg a {
	text-decoration: underline !important;
	font-style: italic;
	position: relative;
}

.wysiwyg-text-container a::before,
.quiz-dialog-wysiwyg a::before {
	opacity: 0;
	pointer-events: none;
	align-items: center;
	position: absolute;
	content: attr(href);
	display: flex;
	bottom: calc(100% + 0px);
	left: 50%;
	transform: translateX(-50%);
	padding: 5px 10px;
	border-radius: 5px;
	border: 1px solid #eee6;
	background: #484848;
	color: white;
	font-size: 12px;
	transition: 300ms ease;
}

.wysiwyg-text-container a:hover::before,
.quiz-dialog-wysiwyg a:hover::before {
	opacity: 1;
	pointer-events: all;
}

.wysiwyg-text-container .ql-size-small,
.quiz-dialog-wysiwyg .ql-size-small {
	font-size: 12px;
}

.wysiwyg-text-container img,
.quiz-dialog-wysiwyg img {
	max-width: 100%;
}

.wysiwyg-text-container h1,
.wysiwyg-text-container h2,
.wysiwyg-text-container h3,
.wysiwyg-text-container h4,
.wysiwyg-text-container h5,
.wysiwyg-text-container h6,
.quiz-dialog-wysiwyg h1,
.quiz-dialog-wysiwyg h2,
.quiz-dialog-wysiwyg h3,
.quiz-dialog-wysiwyg h4,
.quiz-dialog-wysiwyg h5,
.quiz-dialog-wysiwyg h6 {
	margin: 5px 0;
}

.custom-quill {
	height: calc(100% - 50px);
	width: 100%;
}

.ql-blank::before {
	color: inherit !important;
}

.ql-toolbar.ql-snow {
	border-radius: 5px 5px 0 0;
	background-color: whitesmoke;
}

.ql-container.ql-snow {
	border-radius: 0 0 5px 5px;
	background-color: inherit;
}

.borderless .ql-toolbar.ql-snow {
	border-radius: 0;
	border: none !important;
	border-bottom: 1px solid #ccc !important;
}

.borderless .ql-container.ql-snow {
	border-radius: 0;
	border: none !important;
}

.no-tools-quill .ql-toolbar.ql-snow {
	display: none;
}

.no-tools-quill .ql-container.ql-snow {
	border-radius: 5px;
	border-top: 1px solid #ccc !important;
}

.no-tools-quill.borderless .ql-container.ql-snow {
	border-radius: 0;
	border-top: none !important;
}
